export const PROFILES = {
    contratanet: {
        primaryColor: '#82c14f',
        secondaryColor: '#3d4955',
        logoUrl: 'assets/images/logos/contratanet.png',
        language: 'pt-BR'
    },
    blueGray: {
        primaryColor: '#607d8b',
        secondaryColor: '#82c14f',
        logoUrl: 'assets/images/logos/blue-gray.png',
        language: 'es-ES'
    },
    orange: {
        primaryColor: '#ff9800',
        secondaryColor: '#3d4955',
        logoUrl: 'assets/images/logos/orange.png',
        language: 'it-IT'
    },
    red: {
        primaryColor: '#ff9800',
        secondaryColor: '#3d4955',
        logoUrl: 'assets/images/logos/orange.png',
        language: 'fr-FR'
    },
    darkBlueGray: {
        primaryColor: '#3d4955',
        secondaryColor: '#82c14f',
        logoUrl: 'assets/images/logos/dark-blue.png',
        language: 'en-GB'
    }
};
