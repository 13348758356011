import { Component, ElementRef, HostListener, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { Subscription, timer } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ANGULAR_ROUTES } from 'src/app/app-routing.module';
import { RouteAux } from 'src/app/core/models/route-aux.model';
import { StartupConfig } from 'src/app/core/models/startup-config.model';
import { User } from 'src/app/core/models/user';
import { UserDetails } from 'src/app/core/models/user-details';
import { AuthService } from 'src/app/core/services/auth.service';
import { MainPageService } from 'src/app/core/services/main-page.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { RouteConditionService } from 'src/app/core/services/route-condition.service';
import { CredentialService } from 'src/app/core/services/shared/credential.service';
import { LoaderService } from 'src/app/core/services/shared/loader.service';
import { StartupService } from 'src/app/core/services/startup.service';
import { EVALUATION_ROUTES } from 'src/app/modules/evaluation/evaluation-routing.module';
import { LEGACY_ROUTES } from 'src/app/modules/legacy/legacy-routing/legacy-routing.module';
import { CnetDecimalPipe } from '../../pipes/decimal-pipe/decimal.pipe';

@Component({
  selector: 'app-header-toolbar',
  templateUrl: './header-toolbar.component.html',
  styleUrls: ['./header-toolbar.component.scss']
})
export class HeaderToolbarComponent implements OnInit, OnDestroy {
  config = this.startupService.startupConfig;
  user: User;
  userRole: string;
  legacyContext = LEGACY_ROUTES;
  evaluationContext = EVALUATION_ROUTES;
  userDetails: UserDetails = new UserDetails();
  notifications: Array<any> = new Array<any>();


  routesHeader = [];
  routesProfileMenu = [];
  isMobile = false;

  hideLoginBtn = false;

  @ViewChild('menu') menu;
  @ViewChild('cnetNotification') cnetNotification;


  @ViewChild('headerOptions') headerOptions;
  @ViewChildren('menuOption', { read: ElementRef }) allHeaderOptionsRef: QueryList<ElementRef>;

  routesHeaderBreaks = [];
  widthLinks = [];


  @ViewChild('headerOptionsMobile') headerOptionsMobile;
  @ViewChildren('menuMobileOption', { read: ElementRef }) allMobileHeaderOptionsRef: QueryList<ElementRef>;

  widthLinksMobile = [];

  timerSubscriptionNotifications: Subscription;

  constructor(
    private startupService: StartupService,
    private authService: AuthService,
    private loaderService: LoaderService,
    private notificationService: NotificationService,
    private credentialService: CredentialService,
    private _permissionsService: NgxPermissionsService,
    private _routeConditionService: RouteConditionService,
    private _router: Router,
    private mainPageService: MainPageService,
    protected cnetDecimalPipe: CnetDecimalPipe

  ) {
    this._router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.buildHeader();
      }
    });

  }
  ngOnDestroy(): void {
    this.userDetails = new UserDetails();
    if (this.timerSubscriptionNotifications) {
      this.notifications = [];
      this.timerSubscriptionNotifications.unsubscribe();
      this.timerSubscriptionNotifications = null;
    }
  }

  hasTeamManageMembersAndManageCampusAdminUserPermissions() {
    return this._permissionsService.getPermission('ManageCampusAdminUser') && this._permissionsService.getPermission('InstitutionTeamManage')
  }
  ngOnInit() {
    this.credentialService.getCredential().subscribe((credential: string) => {
      if (!credential) {
        return;
      }
      this.buildHeader();
    });

    this._router.events
      .pipe(
        filter(
          event =>
            event instanceof NavigationEnd
        ),
      )
      .subscribe(event => {
        if (event instanceof NavigationEnd) {
          if (event.url === '/auth') {
            this.hideLoginBtn = true;
          } else {
            this.hideLoginBtn = false;
          }
        }
      });

    this.buildHeader();
  }

  buildHeader() {
    if (this.authService.isLoggedIn()) {
      this.routesHeader = [];
      this.routesProfileMenu = [];
      this.user = this.authService.getUser();
      this.userRole = this.authService.getUserRole();

      if (this.userDetails?.shortName) {
        this.createOptionsMenu();
      } else {

        this.authService.getUserDetails()
          .pipe()
          .subscribe(
            userDetails => {
              this.userDetails = userDetails;
              this.createOptionsMenu();
            },
          );
      }

      if (!this.timerSubscriptionNotifications) {
        this.notifications = [];
        this.timerSubscriptionNotifications = timer(0, 20 * 60000).pipe(
          map(() => {
            this.getNotifications();
          })
        ).subscribe();
      }

    }
    else {
      this.test();
      this.userDetails = new UserDetails();
      if (this.timerSubscriptionNotifications) {
        this.timerSubscriptionNotifications.unsubscribe();
        this.timerSubscriptionNotifications = null;
        // this.notifications = [];
      }
    }



  }

  private createOptionsMenu() {

    if (!(this.routesHeader.length && this.routesProfileMenu.length)) {

      this.routesHeader = [];
      this.routesProfileMenu = [];
      this.resolveResize(window.innerWidth);


      this.legacyContext.filter(context => context.path != 'signup').forEach(legacy => {
        if (legacy.children && legacy.children.length) {
          legacy.children.forEach(child => {
            if (child.data != undefined && child.data != null && !child.data.notInHeaderMenu && this.verifyRoles(child)) {

              const permissionOnly = this.verifyPermissionOnly(child.data.permissions?.only);
              const permissionExcept = this.verifyPermissionExcept(child.data.permissions?.except);

              if (permissionOnly && permissionExcept) {
                if (this._routeConditionService.checkConditions(this.userDetails, child.data.conditions)) {
                  if (!child.data.profileMenuItens) {
                    this.routesHeader.push(new RouteAux(legacy.path, child.path, child.data, `l/${legacy.path}/${child.path}`));
                  } else {
                    this.routesProfileMenu.push(new RouteAux(legacy.path, child.path, child.data, `l/${legacy.path}/${child.path}`));
                  }
                }
              }
            }
          });
        }
      });

      const angularRoutes: Array<any> = ANGULAR_ROUTES;

      angularRoutes.forEach((route) => {
        if (route.data && !route.data.headerFixedIcon && !route.data.notInHeaderMenu && this.verifyRoles(route)) {

          const permissionOnly = this.verifyPermissionOnly(route.data.permissions?.only);
          const permissionExcept = this.verifyPermissionExcept(route.data.permissions?.except);

          if (permissionOnly && permissionExcept) {
            if (this._routeConditionService.checkConditions(this.userDetails, route.data.conditions)) {
              if (!route.data.profileMenuItens) {
                this.routesHeader.push(new RouteAux(route.path, '', route.data, route.path));
              } else {
                if (!(route.path === 'update-company' && !this.startupService.allowSerproIntegration()))
                  this.routesProfileMenu.push(new RouteAux(route.path, '', route.data, route.path));
              }
            }
          }
        }
      });
    }
    this.resolveResize(window.innerWidth);

  }

  getNotificationClick() {
    this.getNotifications(true);
  }
  getNotifications(userRequest?: boolean) {
    this.notificationService.getNotifications(userRequest)
      .subscribe(notifications => {
        this.notifications = [];
        this.notifications = notifications;
      });
  }

  verifyRoles(route): boolean {
    return !route.data.roles || route.data.roles.indexOf(this.userRole) > -1;
  }

  logout(): void {
    this.menu.hide();
    setTimeout(() => {
      this.loaderService.forceHide();
      this.test();
      this.authService.logoutAndRedirectToLogin();
    }, 0.5);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.resolveResize(event.target.innerWidth);
  }

  resolveResize(innerWidth) {
    if (innerWidth <= 992) {
      this.isMobile = true;
      this.updateLinksHeaderMobile();
    } else {
      this.isMobile = false;
      this.updateLinksHeader();
    }

  }

  resolveParams(item) {
    return { ...item.parameters.map(param => this.cnetDecimalPipe.transform(param, '1.0')) }
  }

  navigateToRoute(url: string) {
    if (url) {

      if (url.includes('?')) {
        const queryParams = url.substring(url.indexOf('?') + 1).split('&')
        if (queryParams) {
          let angularQueryParams = {};
          queryParams.forEach(param => {
            angularQueryParams[param.split('=')[0]] = param.split('=')[1];
          });
          this._router.navigate([url.substring(0, url.indexOf('?'))], { queryParams: angularQueryParams });
        }
      } else {
        this._router.navigate([url]);
      }
    }
    this.cnetNotification?.hide();
  }

  /**
   * Execute the directive ngxPermissionsOnly
   * @param permission
   */
  private verifyPermissionOnly(permission: string | string[]) {
    return this.verifyHasPermitions(permission);
  }

  /**
   * Execute the directive ngxPermissionsExcept
   * @param permission
   */
  private verifyPermissionExcept(permission: string) {
    return permission ? !this.verifyHasPermitions(permission) : true;
  }

  private verifyHasPermitions(permission: string | string[]) {
    if (permission) {
      if (Array.isArray(permission)) {
        const allow = permission.some(perm => {
          return this._permissionsService.getPermission(perm);
        })
        return allow;
      } else {
        return this._permissionsService.getPermission(permission);
      }
    } else {
      // if there is no permission seted, then its alowed to everyone
      return true;
    }
  }

  showLanguageOption() {
    return this.startupService.showLanguageOption();
  }

  getUser() {
    return this.authService.getUser();
  }
  navigateToLogin() {
    this.mainPageService.navigateToLoginUrl();
  }

  hasBlog() {
    return this.startupService.hasBlog();
  }

  openLink(url) {
    if (url) {
      window.open(url, '_self');
    }
  }

  test() {
    if (!this.getUser()) {
      this.user = this.getUser();
    }
  }

  updateLinksHeader() {
    setTimeout(() => {

      // espaco disponivel e a largura do headerOptions - o tamanho do btn de barras, q e 54px (mesmo se não for necessário, tenho q levar em consideracao)
      const availableSpace = this.headerOptions?.nativeElement?.offsetWidth ? this.headerOptions.nativeElement.offsetWidth - 54 : 0;

      if (this.headerOptions && availableSpace > 0) {
        if (!(this.widthLinks && this.widthLinks.length)) {
          this.allHeaderOptionsRef.map((a, index) => {

            if (this.widthLinks.length === 0) {
              this.widthLinks.push(a.nativeElement.offsetWidth);
            } else {
              this.widthLinks.push(a.nativeElement.offsetWidth + this.widthLinks[index - 1])
            }
            if (this.widthLinks[index] > availableSpace) {
              this.routesHeader[index].visible = false;
              this.routesHeaderBreaks.push(this.routesHeader[index]);
            }
          });

        } else {

          this.routesHeaderBreaks = [];
          this.widthLinks.map((val, index) => {
            if (availableSpace < val) {
              this.routesHeader[index].visible = false;
              this.routesHeaderBreaks.push(this.routesHeader[index]);
            } else {
              this.routesHeader[index].visible = true;

            }
          })

        }
      }
    }, 0);
  }

  updateLinksHeaderMobile() {
    setTimeout(() => {
      // espaco disponivel e a largura do headerOptions - o tamanho do btn de barras, q e 54px (mesmo se não for necessário, tenho q levar em consideracao)
      if (this.headerOptionsMobile) {
        const availableSpace = this.headerOptionsMobile.nativeElement.offsetWidth - 54;
        if (this.headerOptionsMobile && availableSpace > 0) {
          // const widths = this._allOptionsRef.map(a => a._getHostElement().offsetWidth)
          if (!(this.widthLinksMobile && this.widthLinksMobile.length)) {
            this.allMobileHeaderOptionsRef.map((a, index) => {

              if (this.widthLinksMobile.length === 0) {
                this.widthLinksMobile.push(a.nativeElement.offsetWidth);
              } else {
                this.widthLinksMobile.push(a.nativeElement.offsetWidth + this.widthLinksMobile[index - 1])
              }

              if (this.widthLinksMobile[index] > availableSpace) {

                this.routesHeader[index].visible = false;
                this.routesHeaderBreaks.push(this.routesHeader[index]);

              }

            });

          } else {

            this.routesHeaderBreaks = [];
            this.widthLinksMobile.map((val, index) => {
              if (availableSpace < val) {
                this.routesHeader[index].visible = false;
                this.routesHeaderBreaks.push(this.routesHeader[index]);
              } else {
                this.routesHeader[index].visible = true;

              }
            })

          }
        }
      }
    }, 0);
  }
}
