import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, catchError, map, of } from 'rxjs';
import { StartupService } from '../services/startup.service';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceGuard {
  router = inject(Router)
  startup = inject(StartupService)
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.startup.initRequest(true).pipe(
      map(config => {
        if (this.startup.hasCorsErrorOnRequestDifferentThanStartup) {
          this.router.navigate(['internal-server-error']);
        } else {
          const lastRoute = localStorage.getItem('lastRouteBeforeError')
          if (lastRoute) {
            localStorage.removeItem('lastRouteBeforeError')
          }
          this.router.navigate([lastRoute ?? '']);
        }
        return false;
      }),
      catchError((err) => {
        return of(true)
      })
    )
  }

}
