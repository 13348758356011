declare var window: any;

export class DynamicEnvironment {
  public get env() {
    return window?.config?.env;
  }
  public get apiUrl(): string {
    return window?.config?.apiUrl;
  }
  public get viaCepApiUrl(): string {
    return window?.config?.viaCepApiUrl;
  }
  public get httpSchema(): string {
    return window?.config?.httpSchema;
  }
  public get googleAnalyticsGeral(): string {
    return window?.config?.googleAnalyticsGeral;
  }
  public get oldGoogleAnalyticsGeral(): string {
    return window?.config?.oldGoogleAnalyticsGeral;
  }
  public get recaptchaKey(): string {
    return window?.config?.recaptchaKey;
  }
  public get retryDelay(): number {
    return window?.config?.retryDelay ? parseInt(window?.config?.retryDelay) : 300;
  }
  public get maximumRetries(): number {
    return window?.config?.maximumRetries ? parseInt(window?.config?.maximumRetries) : 3;
  }
}
