<cnet-tab-group>
    <cnet-tab tab-title="Colors">
        <p>Primary color</p>
        <div class="color-options">
            <button cnet-icon-button size="small" *ngFor="let color of colors | keyvalue"
                [ngStyle]="{'background': color.value}" (click)="setColor('primary', color.value)"></button>
        </div>

        <p>Secondary color</p>
        <div class="color-options">
            <button cnet-icon-button size="small" *ngFor="let color of colors | keyvalue"
                [ngStyle]="{'background': color.value}" (click)="setColor('secondary', color.value)"></button>
        </div>
    </cnet-tab>
    <cnet-tab tab-title="Language">
        <cnet-form-field>
            <cnet-field-label>Choose a language</cnet-field-label>

            <cnet-select placeholder="Select an option" #select (change)="setLanguage($event)">
                <cnet-select-option value="pt-BR" viewValue="pt-BR"></cnet-select-option>
                <cnet-select-option value="en-GB" viewValue="en-GB"></cnet-select-option>
                <cnet-select-option value="en-AE" viewValue="en-AE"></cnet-select-option>
                <cnet-select-option value="it-IT" viewValue="it-IT"></cnet-select-option>
                <cnet-select-option value="es-ES" viewValue="es-ES"></cnet-select-option>
                <cnet-select-option value="fr-FR" viewValue="fr-FR"></cnet-select-option>
            </cnet-select>
        </cnet-form-field>
    </cnet-tab>
    <cnet-tab tab-title="Profile">
        <button cnet-filled-button *ngFor="let profile of profiles | keyvalue" (click)="setProfile(profile.value)">
            {{profile.key}}
        </button>
    </cnet-tab>
</cnet-tab-group>
