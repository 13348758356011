
import { CommonModule } from '@angular/common';
import { Component, computed, inject, OnInit, signal } from '@angular/core';
import { StartupService } from 'src/app/core/services/startup.service';
import { SharedModule } from '../../shared.module';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [CommonModule, SharedModule],
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

export class FooterComponent {

  private startupService = inject(StartupService)

  config = this.startupService.startupConfig;
  socialMedia = computed(() => this.config()?.portal.socialMedia ?? null);
  hasLinks = computed(() => this.socialMedia() ? !Object.values(this.socialMedia()).every(value => value === null) : false);
  links = computed(() => this.socialMedia() ?
    Object.keys(this.socialMedia()).map(key => { return { href: this.socialMedia()[key] ? `https://www.${key}.com/${key === 'linkedIn' ? 'company/' : ''}${this.socialMedia()[key]}` : this.socialMedia()[key], icon: key.toLocaleLowerCase(), title: key } })
    : [])

}
