<footer>
  <section class="ft-main">
    @if(hasLinks()){
    <div class="ft-main-item">
      <ul class="ft-social-list m-0">
        @for(link of links(); track link.href){
        <li>
          <a *ngIf="link.href" [href]="link.href" target="_blank" [title]="link.title" class="ctn-social-icon"
            [ngClass]="'ctn-social-icon-'+link.icon" [id]="link.title">
            <cnet-icon [name]="link.icon" [label]="link.title"></cnet-icon>
          </a>
        </li>
        }
      </ul>
    </div>
    }
    <div class="ft-main-item">
      <a href="https://www.contratanet.com.br" target="_blank" rel="nofollow">
        <img src="/assets/images/logos/symplicity/logo-symp-cnet-dark.svg" alt="Contratanet" width="254">
      </a>
    </div>
  </section>
</footer>
