import { Directive, EventEmitter, HostListener, inject, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';

@Directive({
  selector: '[esater]',
  standalone: true
})
export class NotAnEasterEggDirective {

  private sequence: string[] = [];
  private gameCode = [
    'arrowup', 'arrowup',
    'arrowdown', 'arrowdown',
    'arrowleft', 'arrowright',
    'arrowleft', 'arrowright',
    'b', 'a'
  ];;
  router = inject(Router)
  auth = inject(AuthService)

  navigateToManager() {
    this.router.navigate(['/auth/manager'])
  }

  isGameCode(): boolean {
    return this.gameCode.every((code: string, index: number) => code === this.sequence[index]);
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (!this.auth.isLoggedIn()) {
      if (event.key) {
        this.sequence.push(event.key.toLowerCase());

        if (this.sequence.length > this.gameCode.length) {
          this.sequence.shift();
        }

        if (this.isGameCode()) {
          this.navigateToManager();
        }
      }
    }
  }
}
