import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, catchError, map, retry, takeUntil, timer } from 'rxjs';
import { StartupService } from 'src/app/core/services/startup.service';
import { SharedModule } from '../../shared.module';
import { LanguageSwitcherModule } from '../../components/language-switcher/language-switcher.module';

@Component({
  selector: 'app-maintenance',
  standalone: true,
  imports: [CommonModule, SharedModule, LanguageSwitcherModule],
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent implements OnInit, OnDestroy {

  startup = inject(StartupService)
  router = inject(Router)
  private timer = 60000 * 30
  private readonly _destroyed = new Subject<void>();

  constructor() { }

  ngOnInit(): void {
    setTimeout(() => {
      this.startup.init().pipe(
        takeUntil(this._destroyed),
        map(() => {
          const lastRoute = localStorage.getItem('lastRouteBeforeError')
          if (lastRoute) {
            localStorage.removeItem('lastRouteBeforeError')
          }
          this.router.navigate([lastRoute ?? '']);
          return false;
        }),
        catchError((err) => {
          return err
        }),
        retry({
          delay: (error) => {
            return timer(this.timer);
          }
        }),
      ).subscribe();
    }, this.timer);

  }

  ngOnDestroy(): void {
    this._destroyed.next();
    this._destroyed.complete();
  }

}
